
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import {Prop, PropSync} from 'vue-property-decorator';
    import {getModule} from 'vuex-module-decorators';

    import {
        BookingModule,
        SnackbarModule,
        TuvcomModule,
    } from '@/store/modules';

    import Openings from '@/components/Openings.vue';

    import {
        IBooking, 
        IMoveBookingParams
    } from '@/types';

    import {tuvcomHelper} from '@/helpers';

    @Component<MoveAppointmentDialog>({
        components: {
            Openings,
        },
    })
    export default class MoveAppointmentDialog extends Vue {
        @Prop({
            type: Object,
            required: true,
        })
        private appointment!: IBooking;

        @PropSync('opened', {
            type: Boolean,
            default: () => false,
        })
        private openedSync!: boolean;

        public moveBooking({time, agendas}: {time: string, agendas: string[]}) {
            (
                tuvcomHelper.isTuvcom(this.$route) ?
                getModule(TuvcomModule, this.$store) :
                getModule(BookingModule, this.$store)
            )
                .moveBooking(
                    tuvcomHelper.isTuvcom(this.$route) ?
                    {
                        start: time,
                    } :
                    {
                        client_id: this.appointment.client.id,
                        booking_id: this.appointment.id,
                        start: time,
                    } as IMoveBookingParams,
                )
                .then(() => this.onMoveBookingSuccess())
                .catch(() => this.onMoveBookingError())
            ;
        }

        public onMoveBookingSuccess() {
            this.openedSync = false;

            getModule(SnackbarModule, this.$store)
                .display({
                    message: 'Votre rendez-vous a bien été déplacé',
                    position: 'top',
                    color: 'success',
                })
            ;
        }

        public onMoveBookingError() {
            getModule(SnackbarModule, this.$store)
                .display({
                    message: 'Une erreur est survenue. Veuillez réessayer ulterieurement.',
                    position: 'top',
                    color: 'error',
                })
            ;
        }
    }
