
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import {Prop, PropSync} from 'vue-property-decorator';
    import {getModule} from 'vuex-module-decorators';

    import {
        BookingModule,
        SnackbarModule,
        TuvcomModule,
    } from '@/store/modules';

    import {IBooking, ICancelBookingParams} from '@/types';

    import {tuvcomHelper} from '@/helpers';

    @Component<CancelAppointmentDialog>({})
    export default class CancelAppointmentDialog extends Vue {
        @Prop({
            type: Object,
            required: true,
        })
        public appointment!: IBooking;

        @PropSync('opened', {
            type: Boolean,
            default: () => false,
        })
        private openedSync!: boolean;

        get params() {
            if (tuvcomHelper.isTuvcom(this.$route)) {
                return {};
            } else {
                return {
                    client_id: this.appointment.client.id,
                    booking_id: this.appointment.id,
                };
            }
        }

        public cancelBooking() {
            (
                tuvcomHelper.isTuvcom(this.$route) ?
                getModule(TuvcomModule, this.$store) :
                getModule(BookingModule, this.$store)
            )
                .cancelBooking(this.params as ICancelBookingParams)
                .then(() => this.onCancelSuccess())
                .catch(() => this.onCancelError())
            ;
        }

        public onCancelSuccess() {
            this.openedSync = false;

            getModule(SnackbarModule, this.$store)
                .display({
                    message: 'Votre rendez-vous a bien été annulé',
                    position: 'top',
                    color: 'success',
                })
            ;
        }

        public onCancelError() {
            getModule(SnackbarModule, this.$store)
                .display({
                    message: 'Une erreur est survenue. Veuillez réessayer ulterieurement.',
                    position: 'top',
                    color: 'error',
                })
            ;
        }
    }
