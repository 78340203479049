
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import {Prop, PropSync} from 'vue-property-decorator';
    import {getModule} from 'vuex-module-decorators';
    import {namespace} from 'vuex-class';
    import {DateTime} from 'luxon';
    import AppointmentCardFile from '@/components/AppointmentCardFile.vue';

    import {
        BookingModule,
        SnackbarModule,
        TuvcomModule,
    } from '@/store/modules';

    import {
        IBooking,
        ICreateBookingFilesResult,
        ICreateBookingFilesError,
        ICommentAndFile,
        IBookingCommentParams,
    } from '@/types';

    import {tuvcomHelper} from '@/helpers';

    const bookingNamespace = namespace('booking');
    const tuvcomNamespace = namespace('tuvcom');

    @Component<CommentAppointmentDialog>({})
    export default class CommentAppointmentDialog extends Vue {
        public filesToUpload: File[] = [];
        public isUploadingFiles: boolean = false;
        public DATETIME_SHORT = DateTime.DATETIME_SHORT;
        private comment: string|null = null;
        private commentsValid: boolean = false;
        private comments: string|null = null;
        public isLoadingFiles: boolean = false;
        private submittingComment: boolean = false;

        @Prop({
            type: Object,
            required: true,
        })
        private appointment!: IBooking;

        private get isDisabled(): boolean {
            return !this.comment && this.filesToUpload.length === 0;
        }

        @bookingNamespace.Getter('commentsAndFilesList')
        public bookingCommentsAndFiles!: ICommentAndFile[];
    
        @tuvcomNamespace.Getter('commentsAndFilesList')
        public tuvcomCommentsAndFiles!: ICommentAndFile[];
        
        get commentsAndFilesList() {
            if (tuvcomHelper.isTuvcom(this.$route)) {
                return this.tuvcomCommentsAndFiles;
            }

            return this.bookingCommentsAndFiles;
        }


        @PropSync('opened', {
            type: Boolean,
            default: () => false,
        })
        private openedSync!: boolean;

        get commentParams(): IBookingCommentParams {
            if (tuvcomHelper.isTuvcom(this.$route)) {
                return {
                    comment: this.comments as string,
                };
            } else {
                return {
                    clientId: this.appointment.client.id as string,
                    bookingId: this.appointment.id as string,
                    comment: this.comments as string,
                };
            }
        }

        private close() {
            this.openedSync = false;
            this.comments = null;
        }

        private get isLoading(): boolean {
            return this.isLoadingFiles || this.submittingComment;
        }

        private commentBooking() {
            this.submittingComment = true;
            (
                tuvcomHelper.isTuvcom(this.$route) ?
                getModule(TuvcomModule, this.$store) :
                getModule(BookingModule, this.$store)
            )
                .commentBooking(this.commentParams as any)
                .then(() => this.onCommentSuccess())
                .catch((error) => this.onCommentError())
            ;
        }

        private uploadFiles() {
            this.isUploadingFiles = true;

            const formData = new FormData();

            this.filesToUpload.forEach((file: File, index: number) => {
                formData.append(`files[${index}]`, file);
            });

            (
                tuvcomHelper.isTuvcom(this.$route) ?
                getModule(TuvcomModule, this.$store) :
                getModule(BookingModule, this.$store)
            )
                .addFiles(
                    tuvcomHelper.isTuvcom(this.$route) ?
                    formData :
                    {
                        client_id: this.appointment.client.id,
                        booking_id: this.appointment.id,
                        files: formData,
                    } as any,
                )
                .then((response: ICreateBookingFilesResult) => this.onUploadSuccess(response))
                .catch(() => this.onUploadError())
                .finally(() => this.isUploadingFiles = false);
        }

        private onCommentSuccess() {
            // this.close();
            this.comments = '';

            getModule(SnackbarModule, this.$store)
                .display({
                    message: 'Votre message a bien été envoyé',
                    position: 'top',
                    color: 'success',
                })
            ;
        }

        private submit() {
            if (this.comment) {
                this.commentBooking();
            }

            this.comment = '';

            if (this.filesToUpload.length > 0) {
                this.uploadFiles();
            }

            this.filesToUpload = [];
        }

        private onCommentError() {
            getModule(SnackbarModule, this.$store)
                .display({
                    message: 'Une erreur est survenue. Veuillez réessayer ulterieurement.',
                    position: 'top',
                    color: 'error',
                })
            ;
        }

        private onUploadSuccess(response: ICreateBookingFilesResult) {
            const snackbarModule = getModule(SnackbarModule, this.$store);

            if (response.errors.length > 0) {
                const failedFiles =
                    response.errors.map((value: ICreateBookingFilesError) => {
                        return value.file;
                    })
                        .join(', ')
                ;

                snackbarModule.display({
                    message: `Une erreur est survenue pendant l'envoi des fichiers suivant : ${failedFiles}`,
                    position: 'top',
                    color: 'error',
                });
            } else {
                snackbarModule.display({
                    message: 'Vos fichiers ont bien été ajoutés !',
                    position: 'top',
                    color: 'success',
                });

                this.$emit('added');
            }

            this.filesToUpload = [];
        }

        private onUploadError() {
            getModule(SnackbarModule, this.$store)
                .display({
                    message: `Une erreur est survenue pendant l'envoi`,
                    position: 'top',
                    color: 'error',
                })
            ;
        }
    }
