
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import {Prop} from 'vue-property-decorator';
    import {namespace} from 'vuex-class';
    import {getModule} from 'vuex-module-decorators';
    import {DateTime} from 'luxon';

    import {
        IBooking,
        IBookingFile,
        IFileParams
    } from '@/types';

    import {
        BookingModule,
        SnackbarModule,
        TuvcomModule,
    } from '@/store/modules';

    import {tuvcomHelper} from '@/helpers';

    const tuvcomNamespace = namespace('tuvcom');

    @Component<AppointmentCardFile>({})
    export default class AppointmentCardFile extends Vue {
        public imageUrl: string = '';

        public confirmDeleteFileDialog: boolean = false;
        public fileToDelete: IBookingFile|null = null;
        public isDeletingFile: boolean = false;

        public DATETIME_SHORT = DateTime.DATETIME_SHORT;

        @Prop({
            type: Object,
            required: true,
        })
        public file!: IBooking;

        @Prop({
            type: Object,
            required: true,
        })
        public appointment!: IBooking;

        @tuvcomNamespace.Getter('tuvcomToken')
        public tuvcomToken!: string;

        public created() {
            if (tuvcomHelper.isTuvcom(this.$route)) {
                return this.$api
                    .tuvcom
                    .getBookingFile({
                        token: this.tuvcomToken,
                        file_id: this.file.id,
                    })
                    .then((url: string) => {
                        this.imageUrl = url;
                    })
                ;
            }

            this.$api.booking
                .getBookingFile({
                    client_id: this.appointment.client.id,
                    booking_id: this.appointment.id,
                    file_id: this.file.id,
                })
                .then((url: string) => {
                    this.imageUrl = url;
                });
        }

        private getFileLink(file: IBookingFile) {
            // TODO: handle expiration date, fetch file link only if it has expired
            if (tuvcomHelper.isTuvcom(this.$route)) {
                return this.$api
                    .tuvcom
                    .getBookingFile({
                        token: this.tuvcomToken,
                        file_id: this.file.id,
                    })
                    .then((url: string) => window.open(url, '_blank'))
                ;
            }

            this.$api.booking
                .getBookingFile({
                    client_id: this.appointment.client.id,
                    booking_id: this.appointment.id,
                    file_id: file.id,
                })
                .then((url: string) => window.open(url, '_blank'))
            ;
        }

        private deleteFile(file: IBookingFile) {
            this.fileToDelete = file;
            this.confirmDeleteFileDialog = true;
        }

        private sendDeleteFileRequest() {
            if (!this.fileToDelete) {
                return;
            }

            this.isDeletingFile = true;

            const snackbarModule = getModule(SnackbarModule, this.$store);

            (
                tuvcomHelper.isTuvcom(this.$route) ?
                getModule(TuvcomModule, this.$store) :
                getModule(BookingModule, this.$store)
            )
                .deleteFile(
                    tuvcomHelper.isTuvcom(this.$route) ?
                    this.file.id :
                    {
                        client_id: this.appointment.client.id,
                        booking_id: this.appointment.id,
                        file_id: this.file.id,
                    } as IFileParams | any,
                )
                .then(() => snackbarModule.displaySuccess('Votre fichier a bien été supprimé !'))
                .catch(() => snackbarModule.displayError())
                .finally(() => {
                    this.fileToDelete = null;
                    this.isDeletingFile = false;
                })
            ;
        }
    }
